<template>
  <div class="search">
    <div class="background-img"></div>
    <form class="container" @submit.prevent="onSubmitForm">
      <div class="center">
        <h1 style="font-size: 32pt; color: white">Search Result</h1>
      </div>
      <div>
        <input
          class="input"
          placeholder="Please enter Email"
          type="email"
          name="email"
          id="email"
          v-model="email"
        />
      </div>
      <div>
        <input
          class="input"
          type="text"
          placeholder="Please enter Task Id"
          name="id"
          id="id"
          v-model="id"
        />
      </div>
      <div class="center">
        <button type="submit" class="button1">Search</button>
      </div>
    </form>
    <div class="center">
      <img
        style="width: 1006px; margin-top: 22px"
        src="../assets/singleprediction/photo1.png"
      />
    </div>
    <!-- // <Loading v-show="isLoading"></Loading> -->
  </div>
</template>

<script>
// import loadingfrom "../components/loading.vue";
import { historyApi } from "../api/index";

export default {
  data() {
    return {
      email: null,
      id: null,
      // isLoading: false,
    };
  },
  components: {
    // Loading: loading,
  },
  methods: {
    checkForm() {
      if (!this.email) {
        this.$store.commit("alertMsg", {
          msg: "Enter the eamil!",
          type: "error",
        });
        return false;
      }
      if (!this.id) {
        this.$store.commit("alertMsg", {
          msg: "Enter the tast id!",
          type: "error",
        });
        return false;
      }
      const emailReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!emailReg.test(this.email)) {
        this.$store.commit("alertMsg", {
          msg: "Wrong email format!",
          type: "error",
        });
        return false;
      }
      return true;
    },
    onSubmitForm() {
      if (this.checkForm()) {
        console.log(this.email, this.id);
        this.$store.commit("startLoading");
        historyApi
          .getResultById(this.email, this.id)
          .then((res) => {
            if (res.code == 200) {
              console.log(res);
              const input_content = res.data.input_content.split(",");
              switch (res.data.type) {
                case "SEL":
                  this.$router.push({
                    name: "VirtualResult",
                    params: {
                      resultId: this.id,
                      email: this.email,
                    },
                  });
                  break;
                case "PRE":
                  if (input_content.length == 1) {
                    this.$router.push({
                      name: "SinglePredictResult",
                      params: {
                        resultId: this.id,
                        email: this.email,
                      },
                    });
                  } else {
                    this.$router.push({
                      name: "MultiPredictResult",
                      params: {
                        resultId: this.id,
                        email: this.email,
                      },
                    });
                  }
                  break;
                case "SIM":
                  this.$router.push({
                    name: "SimilarityResult",
                    params: {
                      resultId: this.id,
                      email: this.email,
                    },
                  });
                  break;
                default:
                  break;
              }
            } else {
              this.$store.commit("alertMsg", {
                msg: res.msg,
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$store.commit("alertMsg", {
              msg: err,
              type: "error",
            });
          })
          .finally(() => {
            this.$store.commit("endLoading");
          });
      }
    },
  },
};
</script>

<style scoped>
.search {
  min-height: 80vh;
  padding-top: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.background-img {
  z-index: 1;
  background-image: url(../assets/singleprediction/big_logo.png);
  background-size: 60vmax;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.input {
  width: 711px;
  padding-left: 18px;
  border: none;
  font-size: 20pt;
  outline: none;
  /* font-weight: bold; */
  margin-top: 50px;
  letter-spacing: 1px;
  border-radius: 10px;
  height: 50px;
}
.container {
  z-index: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.button1:hover {
  background: linear-gradient(to left, #8bbbed, #e8f4ff);
}

.button1 {
  margin-top: 40px;
  width: 328px;
  height: 47px;
  /* color: black; */
  /* font-weight: bold; */
  font-size: 20pt;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: black;
  background: linear-gradient(to right, #8bbbed, #e8f4ff);
}
</style>